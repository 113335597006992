import { graphql } from "gatsby";
import React, { useState } from "react";

import BreadCrumb from "../components/common/breadcrumb";
import { IBreadcrumbItem } from "../components/common/breadcrumb/breadcrumb_item";
import SEO from "../components/seo";
import Layout from "../layout/";
import CategoryProductList from "../components/category/category-product-list"


const MAX_PRODUCT_DISPLAY = 23;

const CategoryPage = ({ data }) => {
  const category = data?.strapiCategory;
  // Sort Product alphabetically
  const products: any[] =
    data?.strapiCategory.products?.sort((a, b) =>
      a.productName.localeCompare(b.productName)
    ) ?? [];
  // Get Japanese Product Name
  const categoryNameInJa =
    category.localizations.data?.filter(
      (locale) => locale.attributes.locale === "ja"
    )[0]?.attributes?.name ?? category.name;

  // Tuncate List accound to MAX_PRODUCT_DISPLAY
  const [filterProducts, filterProductsSet] = useState<any[]>(
    products.slice(0, MAX_PRODUCT_DISPLAY)
  );

  const breadcumbItems: IBreadcrumbItem[] = [
    {
      label: categoryNameInJa,
    },
  ];

  const hasMore = () => filterProducts.length < products.length;

  const loadMore = () => {
    const currentLength = filterProducts.length;
    const nextLength = currentLength + MAX_PRODUCT_DISPLAY;
    filterProductsSet((prev) => {
      const newList = [...prev, ...products.slice(currentLength, nextLength)];
      return newList;
    });
  };

  return (
    <Layout>
      {/* <h1>{category.name}</h1> */}
      <BreadCrumb items={breadcumbItems} />
      <div className="h-4"></div>
     
      <CategoryProductList products={filterProducts} category={category} categoryNameInJa={categoryNameInJa} hasMore={hasMore} loadMore={loadMore} />
    </Layout>
  );
};


export const Head = ({ location, data }) => {
  const category = data?.strapiCategory;
  // Get Japanese Product Name
  const categoryNameInJa =
    category.localizations.data?.filter(
      (locale) => locale.attributes.locale === "ja"
    )[0]?.attributes?.name ?? category.name;
  return (
    <SEO
      title={`${categoryNameInJa} | DXies`}
      description={"DXiesでは、デジタルトランスフォーメーションに欠かせない世界のクラウド製品をカテゴリーごとに掲載。貴重な日本語での情報をお届けします。"}
      path={location.pathname}
    />
  );
};



export default CategoryPage;

export const query = graphql`
  query GetCategoryData($id: String) {
    strapiCategory(id: { eq: $id }) {
      id
      slug
      name
      localizations {
        data {
          attributes {
            name
            locale
          }
        }
      }
      products {
        productName
        slug
        logoBackgroundColor
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`;
