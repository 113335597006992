import React from "react";
import { motion } from "framer-motion";
import ProductItem from "../../common/product/product_item";
import { getImage } from "gatsby-plugin-image";
import ProductContainer from "../../common/product/product_container";
import CustomButton from "../../common/button";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

type CategoryProductListProp = {
  products ?: any[];
  category : any;
  categoryNameInJa: string;
  loadMore : void;
  hasMore : boolean;
};

const CategoryProductList = ({ products, category, categoryNameInJa, hasMore, loadMore }: CategoryProductListProp) => {
  if (!products || products.length<=0 ) {
    return <p className="text-sm">このカテゴリの製品は現在掲載されていません。近日中に追加予定です。</p>
  }
  return (
    <ProductContainer>
      {products.map((product) => (
        <motion.div
          className="item"
          variants={item}
          initial="hidden"
          animate="visible"
          key={product?.productName}
        >
          <ProductItem
            name={product?.productName}
            slug={product?.slug}
            image={getImage(product?.logo?.localFile)}
            logoBackgroundColor={product?.logoBackgroundColor}
            currentCategory={{
              id: category?.id,
              name: categoryNameInJa,
              slug: category?.slug,
            }}
          />
        </motion.div>
      ))}
      {hasMore() && (
        <div className="flex items-center justify-center w-full aspect-square">
          <CustomButton onClick={loadMore}>Load More</CustomButton>
        </div>
      )}
    </ProductContainer>
  );
};

export default CategoryProductList;
